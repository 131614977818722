import React from 'react';
import Calendar from "../components/Calendar";
import DashboardCalendar from "./DashboardCalendar";


const Homepage = () => {
    return (
        <div>
             <Calendar/>
            {/*<DashboardCalendar/>*/}
        </div>
    )
}

export {Homepage}
